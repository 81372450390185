<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4" style="font-weight:100">500</h1>
            <h4 class="pt-3">Internal Server Error!</h4>
          </div>
          <div>
            <p class="text-muted">찾고 있는 페이지를 로드하는 데 문제가 있습니다.<br/> 잠시 후에 재 접속해주세요.</p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Error500'
}
</script>
